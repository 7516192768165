import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import "antd/dist/antd.css";
import ReactGA from "react-ga4";
import Router from "./router";
import i18n from "./translation";
import { declineCookies, userAcceptedCookie } from "./utils/cookies";
import {useEffect} from "react";



const App = () => {
    useEffect(() => {
        if (userAcceptedCookie()) {
                const trackingId = process.env.REACT_APP_GA_TRACKING_ID || "";
                ReactGA.initialize(trackingId);
                console.log(ReactGA._hasLoadedGA)
                ReactGA.send({ hitType: "pageview", page: window.location.pathname });

        } else {
            declineCookies()
        }
    }, [])

    return (
  <BrowserRouter>
    <I18nextProvider i18n={i18n}>
      <Router />
    </I18nextProvider>
  </BrowserRouter>
)}

ReactDOM.render(<App />, document.getElementById("root"));
