import Cookies from 'js-cookie'

const declineCookies = () => {
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  };
  
  const userAcceptedCookie = () => 
      Cookies.get("rejected_cookies") === 'false'
  
  

export { declineCookies, userAcceptedCookie }