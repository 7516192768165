const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/privacy-policy"],
    exact: true,
    component: "PrivacyPolicy",
  },
  {
    path: ["/"],
    exact: false,
    component: "404"
  }
];

export default routes;
